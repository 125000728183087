import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card, Col, Container, Spinner } from "react-bootstrap";
import { AssignUserToShopConfirmationInput, AssignUserToShopConfirmationOutput } from "../../../client/core";
import { Color, Path } from "../../../env";
import { Button, variant } from "../../form/Button";

export interface AddUserToShopState {
    isLoading: boolean
    error: boolean
}

export interface AddUserToShopProps extends RouteComponentProps {
    assignUserToShopConfirmation: (input: AssignUserToShopConfirmationInput) => Promise<AssignUserToShopConfirmationOutput | Error>
}

export default class AddUserToShop extends Component<AddUserToShopProps, AddUserToShopState> {
    constructor(props: AddUserToShopProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: false,
        };
    }

    componentDidMount(): void {
        const queryParams = new URLSearchParams(this.props.history.location.search)

        const code = queryParams.get("code") || ""

        this.props.assignUserToShopConfirmation({
            code: code,
        }).then(response => {
            console.log(response)
            if (response instanceof Error) {
                return this.setState({
                    isLoading: false,
                    error: true,
                })
            }

            if (response.statusCode !== 200) {
                return this.setState({
                    isLoading: false,
                    error: true,
                })
            }

            this.setState({
                isLoading: false,
            })
        })
    }

    handleContinue(): void {
        this.props.history.push(Path.ShopDashboard)
    }

    render() {
        if (this.state.isLoading) {
            return <div style={{ textAlign: "center" }}><Spinner variant="success" animation="border" color={Color.Primary} /></div>
        }

        const props: AddUserToShopViewProps = {
            onContinue: () => this.handleContinue(),
        };

        if (this.state.error) {
            return <ErrorView {...props} />
        }

        return <AddUserToShopSuccess {...props} />;
    }
}

export interface AddUserToShopViewProps {
    onContinue: () => void
}

export const AddUserToShopSuccess: FunctionComponent<AddUserToShopViewProps> = (props) => (
    <Container style={{ padding: "1rem" }} fluid>
        <Col xs={12} md={10} style={{ margin: "auto" }}>
            <Card className={"elevate"} style={{ padding: "1rem" }}>
                <Card.Body>
                    <Card.Text>User has been successfully assigned</Card.Text>
                    <p>The user has been successfully assigned to the shop. Thank you for confirming the assignment. This user can now manage the shops administration through the dashboard.</p>
                    <Button style={{ float: "right" }} onClick={() => props.onContinue()} variant={variant.Primary} name="Continue" />
                </Card.Body>
            </Card>
        </Col>
    </Container>
);

export interface ErrorViewProps {
    onContinue: () => void
}

export const ErrorView: FunctionComponent<ErrorViewProps> = (props) => (
    <Container style={{ padding: "1rem" }} fluid>
        <Col xs={12} md={10} style={{ margin: "auto" }}>
            <Card className={"elevate"} style={{ padding: "1rem" }}>
                <Card.Body>
                    <Card.Text>Could not assign user to shop</Card.Text>
                    <p>We encountered an issue while confirming the user assignment. Unfortunately, the user could not be assigned to your shop at this time. If this error persists please contact support@stumbled.online.</p>
                    <Button style={{ float: "right" }} onClick={() => props.onContinue()} variant={variant.Primary} name="Continue" />
                </Card.Body>
            </Card>
        </Col>
    </Container>
);
